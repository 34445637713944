.career-education {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.career-education::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 1px;
  background: var(--grayColor);
}

.career h3 {
  font-size: 3.1rem;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.c-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.c-box h4 {
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-left: 3px solid var(--blueColor);
  line-height: 1.3rem;
  padding-left: 10px;
}

.c-box strong {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-left: 10px;
  margin-top: 10px;
}

.c-box p {
  color: var(--grayColor);
  font-size: 1rem;
  letter-spacing: 2px;
  padding-left: 10px;
  margin-top: 6px;
}

.c-box .grade {
  color: var(--whiteColor);
}

.c-box .career-desc {
  width: 100%;
  text-align: justify;
  text-justify: inter-word;
  max-width: 470px;
}

.btn-c {
  text-align: center;
  margin-top: 50px;
}

/* Responsive */
@media (max-width: 600px) {
  .career-education {
    flex-wrap: wrap;
    justify-content: center;
  }

  .career-education::before {
    display: none;
  }

  .career {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .education {
    margin-top: 50px;
  }
}
