#summary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.summary-heading {
  width: 100%;
  max-width: 470px;
}

.summary-heading strong {
  font-size: 1rem;
  color: var(--blueColor);
  text-transform: uppercase;
  letter-spacing: 3px;
}

.summary-heading h2 {
  font-size: 2rem;
  line-height: 2.9rem;
  margin: 20px 0;
}

.summary-details {
  width: 100%;
  text-align: justify;
  text-justify: inter-word;
  max-width: 500px;
  border: 1px dashed var(--grayColor);
  padding: 15px 20px;
  color: var(--grayColor);
  font-size: 1rem;
}

/* Responsive */
@media (max-width: 865px) {
  #summary {
    justify-content: center;
    flex-wrap: wrap;
  }
  .summary-heading,
  .summary-details {
    max-width: none;
  }

  .summary-details {
    margin-top: 30px;
  }
}
